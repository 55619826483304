/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import "./Login.css";
import { useSearchParams } from "react-router-dom";
import { oauthService } from "src/services/OAuth.service";
import { ILoginInfo } from "./services/OAuth.interface";
import LOADING_SVG from "./loading.svg";

const App: React.FC = () => {
  const [loginInfo, setLoginInfo] = useState<ILoginInfo | undefined>();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    getLoginInfo();

    const intervalId = setInterval(getLoginInfo, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const getLoginInfo = async () => {
    if (!id) return;

    const res = await oauthService.getLoginInfo(id);
    if (!res) return;

    if (res.status === "SUCCESS") {
      window.location.href = res.return_url;
    }
    setLoginInfo(res);
  };

  if (!loginInfo) {
    //TODO: Add loading spinner
    return (
      <div>
        <img src={LOADING_SVG} alt="loading" className="loading" />
      </div>
    );
  }

  return (
    <div className={"dashboard"}>
      <div className={"frame-87"}>
        <div className={"text-ng-nhp-appota-crm"}>
          Đăng nhập {loginInfo.partner.name}
        </div>
        <div className={"frame-79"}>
          <div className={"frame-78"}>
            <QRCode className={"ln0ar"} value={String(loginInfo.qr_code)} />
          </div>
          <div className={"qut-m-qr-bng-ng-dng-acheckin"}>
            Quét mã QR bằng ứng dụng ACheckin
          </div>
          <div className={"hoc-nhp-m-88888888--ng-nhp"}>
            <div>
              <span>Hoặc nhập mã</span>
              <span> </span>
              <span>{loginInfo.code}</span>
              <span> </span>
              <span>để đăng nhập</span>
            </div>
          </div>
        </div>
        <div className={"hng-dn-qut-qrcode-v-nhp-m"}>
          <div>
            <span>Hướng dẫn </span>
            <span>quét QRCode</span>
            <span> và </span>
            <span>nhập mã</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
