import { request } from "src/helpers/Request.helper";
import { ILoginInfo } from "./OAuth.interface";
import { OAUTH_API_URL } from "src/env";

const OAuthService = () => {
  const getLoginInfo = async (id: string): Promise<ILoginInfo | undefined> => {
    try {
      const res = await request({
        method: "GET",
        url: OAUTH_API_URL + "/v1/login/web/info",
        params: {
          id,
        },
      });
      return res.data;
    } catch (e) {
      return;
    }
  };

  return {
    getLoginInfo,
  };
};

export const oauthService = OAuthService();
